import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor() { }

    getToken(){  
        return !!localStorage.getItem("userId");  
    } 
}
