import { IndustryTypeComponent } from './main/industry-type/industry-type.component';
import { ComplaintEngineerComponent } from './main/complaint-engineer/complaint-engineer.component';
import { ComplaintDetailsComponent } from './main/complaint-details/complaint-details.component';
import { ViewClientComponent } from './main/view-client/view-client.component';
import { EngineerComponent } from './main/engineer/engineer.component';
import { ComplaintComponent } from './main/complaint/complaint.component';
import { ClientComponent } from './main/client/client.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './main/login/login.component';
import { RegisterComponent } from './main/register/register.component';
import { ProblemComponent } from './main/problem/problem.component';

const routes: Routes = [
  {path: '', redirectTo: '/ticket', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'client', component: ClientComponent, canActivate: [AuthGuard]},
  {path: 'ticket', component: ComplaintComponent, canActivate: [AuthGuard]},
  {path: 'engineer/ticket', component: ComplaintEngineerComponent, canActivate: [AuthGuard]},
  {path: 'employee', component: EngineerComponent, canActivate: [AuthGuard]},
  {path: 'job', component: ProblemComponent, canActivate: [AuthGuard]},
  {path: 'industry-type', component: IndustryTypeComponent, canActivate: [AuthGuard]},
  {path: 'client/:id', component: ViewClientComponent, canActivate: [AuthGuard]},
  {path: 'ticket/:id', component: ComplaintDetailsComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/ticket'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
