import { ComplaintModule } from './../complaint/complaint.module';
import { ClientTicketsComponent, AddTicketDialog } from './tabs/client-tickets/client-tickets.component';
import { ClientProfileComponent } from './tabs/client-profile/client-profile.component';
import { NgModule } from '@angular/core';
import { ViewClientComponent } from './view-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    QuillModule.forRoot(),

    FuseSharedModule,
    ComplaintModule
  ],
  declarations: [
      ViewClientComponent,
      ClientProfileComponent,
      ClientTicketsComponent,
      AddTicketDialog
    ],
    entryComponents: [
        AddTicketDialog
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
      ]
})
export class ViewClientModule { }
