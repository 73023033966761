import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[customAutoFocus]'
})
export class CustomAutoFocusDirective  implements OnInit {

    constructor(private elementRef: ElementRef) { };

    ngOnInit(): void {
      this.elementRef.nativeElement.focus();
    }

}
