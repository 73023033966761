import { Component } from "@angular/core";

@Component({
    selector: 'delete-dialog',
    templateUrl: 'delete-dialog.html'
  })
  export class DeleteDialog {

    constructor(
    ){ }

    ngOnInit(): void
    { }

  }