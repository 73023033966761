import { FuseNavigation } from '@fuse/types';

var role = localStorage.getItem('type');
var employeeHide: boolean = true;
if(role == 'admin'){
    employeeHide = false;
}else{
    employeeHide = true;
}

export const navigation: FuseNavigation[] = [
    {
        id       : 'menu',
        title    : 'Menu',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'ticket',
                title    : 'All Tickets',
                translate: 'All Tickets',
                type     : 'item',
                icon     : 'receipt_long',
                url      : '/ticket',
                role     : ['admin', 'client', 'super admin', 'manager']
            },
            {
                id       : 'ticket',
                title    : 'My Tickets',
                translate: 'My Tickets',
                type     : 'item',
                icon     : 'receipt_long',
                url      : '/engineer/ticket',
                role     : ['admin', 'employee', 'super admin', 'manager']
            },
        ]
    },
    {
        id       : 'menu',
        title    : 'Master',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'client',
                title    : 'Clients',
                translate: 'Clients',
                type     : 'item',
                icon     : 'group',
                url      : '/client',
                role     : ['admin', 'employee', 'client', 'super admin', 'manager']
            },
            {
                id       : 'employee',
                title    : 'Employees',
                translate: 'Employees',
                type     : 'item',
                icon     : 'perm_contact_calendar',
                url      : '/employee',
                role     : ['admin', 'super admin', 'manager']
            }
        ]
    },
    {
        id       : 'work-directory',
        title    : 'Work Directory',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'job',
                title    : 'Job',
                translate: 'Job',
                type     : 'item',
                icon     : 'work_outline',
                url      : '/job',
                role     : ['admin', 'employee', 'client', 'super admin', 'manager']
            },
            {
                id       : 'job',
                title    : 'Industry Types',
                translate: 'Industry Types',
                type     : 'item',
                icon     : 'domain',
                url      : '/industry-type',
                role     : ['admin', 'employee', 'client', 'super admin', 'manager']
            },
        ]
    }
];
