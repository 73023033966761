import { Router } from '@angular/router';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddClientDialog } from 'app/main/client/client.component';
import { DeleteDialog } from 'app/main/dialog/delete-dialog/delete-dialog.component';
import { AddProblemDialog } from 'app/main/problem/problem.component';
import { AddEngineerDialog } from 'app/main/engineer/engineer.component';
import { OrganisationMaster } from 'app/model/OrganisationMaster';
import { FalconService } from 'app/service/falcon.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from "moment";

export interface TicketDialogData {
    type: string;
    id: number;
    ticket: any;
    client_id: number;
}

@Component({
  selector: 'client-tickets',
  templateUrl: './client-tickets.component.html',
  styleUrls: ['./client-tickets.component.scss']
})
export class ClientTicketsComponent implements OnInit {

    @Input()
    clientId: number;

    type = localStorage.getItem('type');

    tickets: any;
    engineers: any;

    loading: boolean = false;
    dataLoading: boolean = false;
    failed: boolean = false;

    constructor(
        public dialog: MatDialog,
        private falconService: FalconService,
        private snackBar: MatSnackBar,
        private router: Router
    ) { 
        this.falconService.getUpdate().subscribe
            (message => { 
                if(message.text == 'Reload Ticket'){
                    this.tickets.length = 0;
                    this.getTicket();
                }
        });
     }

    ngOnInit() {
        this.getTicket();
        this.getEngineer();
    }

    getTicket(){
        this.dataLoading = true;
        this.failed = false;
        this.falconService.getTicket(this.clientId)
        .subscribe((result) => {
            this.tickets = result;
            this.dataLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load');
            this.dataLoading = false;
            this.failed = true;
        });
    }

    openTicketDetail(id){
        this.router.navigateByUrl('ticket/' + id);
    }

    getEngineer(){
        this.falconService.getEngineer()
        .subscribe((result) => {
            this.engineers = result;
        },
        (error) => {
            this.openSnackBar('Failed to load');
        });
    }

    openTicketAddDialog(type, id, ticket, clientId) {
        const dialogRef = this.dialog.open(AddTicketDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: type,
                id: id,
                ticket: ticket,
                client_id: clientId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.tickets.length = 0;
                this.getTicket();
            }
        });
    }

    deleteTicket(id, index){
        const dialogRef = this.dialog.open(DeleteDialog, {
            disableClose: true, 
            autoFocus: false
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result == 1){
                this.loading = true;
                this.falconService.deleteTicket(id)
                .subscribe((result: any) => {
                    this.openSnackBar('Ticket Deleted');
                    this.tickets.splice(index, 1);
                    this.loading = false;
                },
                (error) => {
                    this.openSnackBar('Failed to Delete');
                    this.loading = false;
                });
            }
        });
    }

    onAssignEngineerChange(event, index){
        this.loading = true;
        let data = {
            id: index,
            engineer_id: event.value,
            status: 1
        };
        this.falconService.assignEngineer(data)
        .subscribe((result) => {
            this.getTicket();
            this.openSnackBar('Engineer assigned successfuly');
            this.loading = false;
        },
        (error) => {
            this.loading = false;
            this.openSnackBar('Failed to assign engineer');
        });
    }

    ticketComplete(index){
        this.loading = true;
        let data = {
            'id': index,
            'status': 3
        };
        this.falconService.changeStatus(data)
        .subscribe((result) => {
            this.getTicket();
            this.openSnackBar('Status updated successfuly');
            this.loading = false;
        },
        (error) => {
            this.loading = false;
            this.openSnackBar('Failed to update successfuly');
        });
    }

    ticketApplyForCompletion(index){
        this.loading = true;
        let data = {
            'id': index,
            'status': 4
        };
        this.falconService.changeStatus(data)
        .subscribe((result) => {
            this.getTicket();
            this.openSnackBar('Status updated successfuly');
            this.loading = false;
        },
        (error) => {
            this.loading = false;
            this.openSnackBar('Failed to update successfuly');
        });
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Close', {
          duration: 3000,
        });
    }

}

@Component({
    selector: 'add-ticket-dialog',
    templateUrl: 'add-ticket-dialog.html'
  })
  export class AddTicketDialog {

    ticketForm: FormGroup;

    problem: any;
    clients: any;
    engineers: any;
    clientsLoading: boolean = true;
    problemsLoading: boolean = true;
    engineersLoading: boolean = true;
    clientsFailed: boolean = false;
    problemsFailed: boolean = false;
    engineersFailed: boolean = false;

    estimatedDays;

    filteredClients: Observable<string[]>;
    filteredEngineers: Observable<string[]>;
    filteredProblems: Observable<string[]>;

    selectedClient = '';
    selectedProblem = '';
    selectedEngineer = '';

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: TicketDialogData,
        public dialogRef: MatDialogRef<AddTicketDialog>,
        private _formBuilder: FormBuilder,
        private falconService: FalconService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router
    ){ }

    ngOnInit(): void
    {
        this.getClients();
        this.getProblem();
        this.getEngineer();
        this.ticketForm = this._formBuilder.group({
            client_id: [this.data.client_id, [Validators.required]],
            problem_id: [this.data.ticket.problem_id, [Validators.required]],
            engineer_id: [this.data.ticket.engineer_id, [Validators.required]],
            details: [this.data.ticket.details, [Validators.required]],
            due_date: [this.data.ticket.due_date, [Validators.required]]
        });
        this.selectedClient = this.ticketForm.get('client_id').value;
        this.selectedProblem = this.ticketForm.get('problem_id').value;
        this.selectedEngineer = this.ticketForm.get('engineer_id').value;
        if(!this.ticketForm.get('problem_id').value){
            this.ticketForm.get('due_date').disable();
        }
    }

    filterClients(val) {
        if(this.clients){
            if(!val){
                val = '';
            }
            return this.clients.filter(option=>
                option.organisation_name.toString().toLowerCase().includes(val.toString().toLowerCase()));
        }
    }

    displayClient(cId) {
        if(cId && (this.clients != undefined || this.clients)){
            return this.clients.find(client => client.id === cId).organisation_name;
        }
    }

    checkClient() {
        setTimeout(()=>{   
            if (!this.selectedClient || this.selectedClient !== this.ticketForm.get('client_id').value) {
                this.ticketForm.get('client_id').setValue(null);
                this.selectedClient = '';
            }
        }, 500);
    }

    filterProblems(val) {
        if(this.problem){
            if(!val){
                val = '';
            }
            return this.problem.filter(option=>
                option.problem_name.toString().toLowerCase().includes(val.toString().toLowerCase()));
        }
    }

    displayProblems(pId) {
        if(pId && (this.problem != undefined || this.problem)){
            return this.problem.find(p => p.id === pId).problem_name;
        }
    }

    checkProblem() {
        setTimeout(()=>{  
            if (!this.selectedProblem || this.selectedProblem !== this.ticketForm.get('problem_id').value) {
                this.ticketForm.get('problem_id').setValue(null);
                this.selectedProblem = '';
            }
        }, 500);
    }

    filterEngineers(val) {
        if(this.engineers){
            if(!val){
                val = '';
            }
            return this.engineers.filter(option=>
                option.name.toString().toLowerCase().includes(val.toString().toLowerCase()));
        }
    }

    displayEngineers(eId) {
        if(eId && (this.engineers != undefined || this.engineers)){
            return this.engineers.find(engineer => engineer.id === eId).name;
        }
    }

    checkEngineer() {
        setTimeout(()=>{
            if (!this.selectedEngineer || this.selectedEngineer !== this.ticketForm.get('engineer_id').value) {
                this.ticketForm.get('engineer_id').setValue(null);
                this.selectedEngineer = '';
            }
        }, 500);
    }

    getClients(){
        this.clientsFailed = false;
        this.clientsLoading = true;
        this.falconService.getAllClientsName()
        .subscribe((result: any) => {
            this.clients = result;
            this.filteredClients = this.ticketForm.get('client_id').valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterClients(value))
            );
            this.ticketForm.get('client_id').setValue(this.data.client_id);
            this.clientsLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load');
            this.clientsLoading = false;
            this.clientsFailed = true;
        });
    }

    getProblem(){
        this.problemsFailed = false;
        this.problemsLoading = true;
        this.falconService.getProblem()
        .subscribe((result) => {
            this.problem = result;
            this.filteredProblems = this.ticketForm.get('problem_id').valueChanges
            .pipe(
                startWith(''),
                map(val => this.filterProblems(val))
            );
            this.ticketForm.get('problem_id').setValue(this.data.ticket.problem_id);
            this.problemsLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Problems');
            this.problemsLoading = false;
            this.problemsFailed = true;
        });
    }

    getEngineer(){
        this.engineersFailed = false;
        this.engineersLoading = true;
        this.falconService.getAllEngineersName()
        .subscribe((result) => {
            this.engineers = result;
            this.filteredEngineers = this.ticketForm.get('engineer_id').valueChanges
            .pipe(
                startWith(''),
                map(val => this.filterEngineers(val))
            );
            this.ticketForm.get('engineer_id').setValue(this.data.ticket.engineer_id);
            this.engineersLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Engineers');
            this.engineersLoading = false;
            this.engineersFailed = true;
        });
    }

    openClientAddDialog(type, id, client: OrganisationMaster) {
        const dialogRef = this.dialog.open(AddClientDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: type,
                id: id,
                client: client
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.clients.length = 0;
                this.getClients();
            }
        });
    }

    openProblemAddDialog() {
        const dialogRef = this.dialog.open(AddProblemDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: 'Add',
                id: 0,
                problem_name: '',
                description: ''
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.problem.length = 0;
                this.getProblem();
            }
        });
    }

    openEngineerAddDialog() {
        const dialogRef = this.dialog.open(AddEngineerDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: 'Add',
                id: 0,
                engineer: ''
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.engineers.length = 0;
                this.getEngineer();
            }
        });
    }

    onProblemChange(event){
        this.selectedProblem = event.option.value;
        this.ticketForm.get('due_date').enable();
        this.problem.forEach(element => {
            if(element.id == event.option.value){
                var date: Date = new Date();
                date.setDate( date.getDate() + element.estimated_days );
                this.ticketForm.get('due_date').setValue(date);
            }
        });
    }

    onEngineerChange(event: any) {
        this.selectedEngineer = event.option.value;
    }

    onClientChange(cId){
        this.selectedClient = cId;
        this.data.client_id = cId;
        this.ticketForm.get('client_id').setValue(cId);
    }

    addTicket(){
        this.loading = true;
        var datetime = moment(this.ticketForm.get('due_date').value).utc().format('YYYY/MM/DD HH:mm:ss');
        var data = {
            id: this.data.id,
            org_id: localStorage.getItem('orgId'),
            client_id: this.data.client_id,
            problem_id: this.ticketForm.get('problem_id').value,
            engineer_id: this.ticketForm.get('engineer_id').value,
            details: this.ticketForm.get('details').value,
            created_by: localStorage.getItem('userId'),
            due_date: datetime,
            status: 1
        };
        if(this.data.id == 0){
            this.falconService.addTicket(data)
            .subscribe((result: any) => {
                this.openSnackBar('Ticket successfuly Generated');
                this.loading = false;
                this.dialogRef.close();
                this.router.navigateByUrl('/ticket/' + result.id);
            },
            (error) => {
                console.log(error);
                this.openSnackBar('Failed to Generated Ticket');
                this.loading = false;
            });
        }
        else{
            this.falconService.editTicket(data)
            .subscribe((result) => {
                this.openSnackBar('Ticket successfuly Edited');
                this.loading = false;
                this.dialogRef.close();
            },
            (error) => {
                console.log(error);
                this.openSnackBar('Failed to Edit Ticket');
                this.loading = false;
            });
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Close', {
          duration: 3000,
        });
    }

  }
