import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FalconService } from 'app/service/falcon.service';
import { AddTicketDialog } from '../view-client/tabs/client-tickets/client-tickets.component';
import { DeleteDialog } from '../dialog/delete-dialog/delete-dialog.component';
import {Location} from '@angular/common';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AddEngineerDialog } from '../engineer/engineer.component';
import { AddProblemDialog } from '../problem/problem.component';
import * as moment from 'moment';

@Component({
  selector: 'app-complaint-details',
  templateUrl: './complaint-details.component.html',
  styleUrls: ['./complaint-details.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ComplaintDetailsComponent implements OnInit {

    ticketForm: FormGroup;

    org_id = localStorage.getItem('orgId');
    user_id = localStorage.getItem('userId');

    type = localStorage.getItem('type');
    complaintId: number;
    loading: boolean = false;

    ticket: any;
    ticketLoading: boolean = false;
    ticketFailed: boolean = false;

    ticketLogs: any;

    editDetailsActive: boolean = false;
    editEngineerActive: boolean = false;
    editProblemActive: boolean = false;
    editDueDateActive: boolean = false;

    engineers: any;
    engineersLoading: boolean = true;
    engineersFailed: boolean = false;
    filteredEngineers: Observable<string[]>;
    selectedEngineer = '';

    problem: any;
    problemsLoading: boolean = true;
    problemsFailed: boolean = false;
    filteredProblems: Observable<string[]>;
    selectedProblem = '';

    constructor(
        private titleService: Title,
        private route:ActivatedRoute,
        private falconService: FalconService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private location: Location,
        private _formBuilder: FormBuilder,
    ) {
        this.titleService.setTitle("Falcon - Ticket");
    }

    ngOnInit() {
        this.complaintId = +this.route.snapshot.paramMap.get('id');
        this.getTicket();
        this.getAllTicketLogsById();
        this.ticketForm = this._formBuilder.group({
            // client_id: [this.data.client_id, [Validators.required]],
            problem_id: ['', [Validators.required]],
            engineer_id: ['', [Validators.required]],
            // details: ['', [Validators.required]],
            due_date: ['', [Validators.required]]
        });
        // this.selectedClient = this.ticketForm.get('client_id').value;
        this.selectedProblem = this.ticketForm.get('problem_id').value;
        this.selectedEngineer = this.ticketForm.get('engineer_id').value;
    }

    editTicketByColumn(type, columnId, coulmnDisplayName, valueId, lastValueId, value, lastValue){
        var data = {
            org_id: this.org_id,
            ticket_id: this.complaintId,
            actor_id: this.user_id,
            type: type,
            field_id: columnId,
            field_display_name: coulmnDisplayName,
            from_id: lastValueId,
            from: lastValue,
            to_id: valueId,
            to: value
        };
        this.falconService.editTicketByColumn(data).subscribe((result)=>{
            if(result != true){
                this.ticket.details = lastValue;
                this.openSnackBar('Failed to Update ' + coulmnDisplayName);
            }else{
                this.getAllTicketLogsById();
            }
        },
        (error)=>{
            this.ticket.details = lastValue;
            this.openSnackBar('Failed to Update ' + coulmnDisplayName);
        });
    }

    back(){
        this.location.back();
    }

    getTicket(){
        this.ticketLoading = true;
        this.ticketFailed = false;
        this.falconService.getTicketById(this.complaintId)
        .subscribe((result: any) => {
            this.ticket = result[0];
            this.ticketForm.get('problem_id').setValue(this.ticket.problem_id);
            this.ticketForm.get('due_date').setValue(this.ticket.due_date);
            // this.ticketForm.get('details').setValue(this.ticket.details);
            this.ticketForm.get('engineer_id').setValue(this.ticket.engineer_id);
            this.getEngineer();
            this.getProblem();
            if(this.type == 'employee' && this.ticket.engineer_id != localStorage.getItem('userId')){
                this.router.navigateByUrl('ticket');
            }
            this.ticketLoading = false;
        },
        (error) => {
            this.ticketFailed = true;
            this.ticketLoading = false;
            this.openSnackBar('Failed to load Ticket');
        });
    }

    getAllTicketLogsById(){
        this.falconService.getAllTicketLogsById(this.complaintId)
        .subscribe((result) => {
            if(result){
                this.ticketLogs = result;
            }
        },
        (error) => {
            this.openSnackBar('Failed to load Activities');
        });
    }

    getProblem(){
        this.problemsFailed = false;
        this.problemsLoading = true;
        this.falconService.getProblem()
        .subscribe((result) => {
            this.problem = result;
            this.filteredProblems = this.ticketForm.get('problem_id').valueChanges
            .pipe(
                startWith(''),
                map(val => this.filterProblems(val))
            );
            this.ticketForm.get('problem_id').setValue(this.ticket.problem_id);
            this.problemsLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Problems');
            this.problemsLoading = false;
            this.problemsFailed = true;
        });
    }

    getEngineer(){
        this.engineersFailed = false;
        this.engineersLoading = true;
        this.falconService.getAllEngineersName()
        .subscribe((result) => {
            this.engineers = result;
            this.filteredEngineers = this.ticketForm.get('engineer_id').valueChanges
            .pipe(
                startWith(''),
                map(val => this.filterEngineers(val))
            );
            this.ticketForm.get('engineer_id').setValue(this.ticket.engineer_id);
            this.engineersLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Engineers');
            this.engineersLoading = false;
            this.engineersFailed = true;
        });
    }

    // Details
    openEditDetails(){
        this.editDetailsActive = true;
    }
    
    closeEditDetails(details, type, event?){
        // if(!details){
        //     this.openSnackBar('Details is required');
        // }
        var lastDetails = this.ticket.details;
        if(event){
            if (event.key == "Enter" && event.ctrlKey && type == 'key'){
                event.preventDefault();
                this.ticket.details = details;
                this.editDetailsActive = false;
                
                if(lastDetails != details){
                    this.editTicketByColumn('updated', 'details', 'Details', details, lastDetails, details, lastDetails);
                }
            } else if(event.key == 'Escape' && type == 'key'){
                this.editDetailsActive = false;
            }
        }
        if(type == 'save'){
            this.ticket.details = details;
            this.editDetailsActive = false;
            if(lastDetails != details){
                this.editTicketByColumn('updated', 'details', 'Details', details, lastDetails, details, lastDetails);
            }
            this.editDetailsActive = false;
        }
        if(type == 'close'){
            this.editDetailsActive = false;
        }
    }

    // Problem
    filterProblems(val) {
        if(this.problem){
            if(!val){
                val = '';
            }
            return this.problem.filter(option=>
                option.problem_name.toString().toLowerCase().includes(val.toString().toLowerCase()));
        }
    }

    displayProblems(pId) {
        if(pId && (this.problem != undefined || this.problem)){
            return this.problem.find(p => p.id === pId).problem_name;
        }
    }

    checkProblem() {
        setTimeout(()=>{  
            if (!this.selectedProblem || this.selectedProblem !== this.ticketForm.get('problem_id').value) {
                this.ticketForm.get('problem_id').setValue(this.ticket.problem_id);
                this.selectedProblem = this.ticket.problem_id;
            }
        }, 500);
    }

    onProblemChange(event){
        this.selectedProblem = event.option.value;
    }

    openEditProblem(){
        this.editProblemActive = true;
    }

    closeEditProblem(problemName, type){
        if(type == 'save'){
            var problem_id = this.ticketForm.get('problem_id').value;
            var lastProblem = this.ticket.problem_id;
            var lastProblemName = this.ticket.problem_name;
            this.ticket.problem_name = problemName;
            this.ticket.problem_id = this.ticketForm.get('problem_id').value;
            this.editProblemActive = false;
            if(lastProblem != problem_id){
                this.editTicketByColumn('updated', 'problem_id', 'Job', problem_id, lastProblem, problemName, lastProblemName);
            }
            this.editProblemActive = false;
        }
        if(type == 'close'){
            this.ticketForm.get('problem_id').setValue = this.ticket.problem_id;
            // this.selectedEngineer = this.ticket.engineer_id;
            this.editProblemActive = false;
        }
    }

    // Due Date
    openEditDueDate(){
        this.editDueDateActive = true;
    }

    closeEditDueDate(problemName, type){
        this.editDueDateActive = false;
        if(type == 'save'){
            var due_date = moment(this.ticketForm.get('due_date').value).utc().format('YYYY/MM/DD HH:mm:ss');
            var lastDueDate = this.ticket.due_date;
            this.ticket.due_date = moment(this.ticketForm.get('due_date').value).utc().format('YYYY/MM/DD HH:mm:ss');
            this.editDueDateActive = false;
            if(lastDueDate != due_date){
                this.editTicketByColumn('updated', 'due_date', 'Due Date', due_date, lastDueDate, due_date, lastDueDate);
            }
            this.editDueDateActive = false;
        }
        if(type == 'close'){
            this.ticketForm.get('due_date').setValue = this.ticket.due_date;
            // this.selectedEngineer = this.ticket.engineer_id;
            this.editDueDateActive = false;
        }
    }

    // Engineer
    filterEngineers(val) {
        if(this.engineers){
            if(!val){
                val = '';
            }
            return this.engineers.filter(option=>
                option.name.toString().toLowerCase().includes(val.toString().toLowerCase()));
        }
    }

    displayEngineers(eId) {
        if(eId && (this.engineers != undefined || this.engineers)){
            return this.engineers.find(engineer => engineer.id === eId).name;
        }
    }

    checkEngineer() {
        setTimeout(()=>{
            if (!this.selectedEngineer || this.selectedEngineer !== this.ticketForm.get('engineer_id').value) {
                this.ticketForm.get('engineer_id').setValue(this.ticket.engineer_id);
                this.selectedEngineer = this.ticket.engineer_id;
            }
        }, 500);
    }

    onEngineerChange(event: any) {
        this.selectedEngineer = event.option.value;
    }

    openEditEngineer(){
        this.editEngineerActive = true;
    }

    closeEditEngineer(engineerName, type){
        if(type == 'save'){
            var engineer_id = this.ticketForm.get('engineer_id').value;
            var lastEngineer = this.ticket.engineer_id;
            var lastEngineerName = this.ticket.engineer_name;
            this.ticket.engineer_name = engineerName;
            this.ticket.engineer_id = this.ticketForm.get('engineer_id').value;
            this.editEngineerActive = false;
            if(lastEngineer != engineer_id){
                this.editTicketByColumn('changed', 'engineer_id', 'Employee', engineer_id, lastEngineer, engineerName, lastEngineerName);
            }
            this.editEngineerActive = false;
        }
        if(type == 'close'){
            this.ticketForm.get('engineer_id').setValue = this.ticket.engineer_id;
            // this.selectedEngineer = this.ticket.engineer_id;
            this.editEngineerActive = false;
        }
    }

    // Dialogs
    openTicketAddDialog(type, id, ticket, clientId) {
        const dialogRef = this.dialog.open(AddTicketDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: type,
                id: id,
                ticket: ticket,
                client_id: clientId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.ticket = null;
                this.getTicket();
            }
        });
    }

    openProblemAddDialog() {
        const dialogRef = this.dialog.open(AddProblemDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: 'Add',
                id: 0,
                problem_name: '',
                description: ''
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.problem.length = 0;
                this.getProblem();
            }
        });
    }

    openEngineerAddDialog() {
        const dialogRef = this.dialog.open(AddEngineerDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: 'Add',
                id: 0,
                engineer: ''
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.engineers.length = 0;
                this.getEngineer();
            }
        });
    }

    deleteTicket(id){
        const dialogRef = this.dialog.open(DeleteDialog, {
            disableClose: true, 
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 1){
                this.loading = true;
                this.falconService.deleteTicket(id)
                .subscribe((result: any) => {
                    this.openSnackBar('Ticket Deleted');
                    this.router.navigateByUrl('/ticket');
                    this.loading = false;
                },
                (error) => {
                    this.openSnackBar('Failed to Delete');
                    this.loading = false;
                });
            }
        });
    }

    ticketChangeStatus(statusId, statusName){
        this.loading = true;
        var lastStatusName = '';
        if(this.ticket.status == 0 || !this.ticket.status){
            lastStatusName = 'Unassigned';
        }else if(this.ticket.status == 1){
            lastStatusName = 'Assigned';
        }else if(this.ticket.status == 2){
            lastStatusName = 'Reassign';
        }else if(this.ticket.status == 3){
            lastStatusName = 'Completed';
        }else if(this.ticket.status == 4){
            lastStatusName = 'Applied for Completion';
        }
        var data = {
            org_id: this.org_id,
            ticket_id: this.complaintId,
            actor_id: this.user_id,
            type: 'changed',
            field_id: 'status',
            field_display_name: 'Status',
            from_id: this.ticket.status,
            from: lastStatusName,
            to_id: statusId,
            to: statusName
        };
        this.falconService.editTicketByColumn(data)
        .subscribe((result) => {
            this.ticket = null;
            this.loading = false;
            this.getTicket();
            this.getAllTicketLogsById();
            this.openSnackBar('Status updated successfuly');
        },
        (error) => {
            this.loading = false;
            this.openSnackBar('Failed to update successfuly');
        });
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Close', {
          duration: 3000,
        });
    }

}
